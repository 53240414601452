import styled from 'styled-components';

export const AppWrapper = styled.div`
  height: 100vh;

  .mobile-s {
    display: none;

    @media only screen and (max-width: 480px) {
      display: block;
    }
  }

  .main-s {
    display: block;

    @media only screen and (max-width: 480px) {
      display: none;
    }
  }

  section.ant-layout {
    height: 100%;
    overflow: hidden !important;

    aside.ant-layout-sider {
      /* background-color: #f4f4f4; */
      overflow: auto !important;
    }

    section.ant-layout {
      overflow: auto !important;
      background-color: #fff;
      padding: 0px 10px;

      @media only screen and (max-width: 767px) {
        padding: 0;
      }
    }

    .ant-menu-item {
      /* padding-left: 30px !important; */
      height: fit-content;
    }

    .session_wrap {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 12px;
      padding-bottom: 12px;

      .name {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        padding: 0;
        margin: 0;
      }

      .number {
        font-size: 10px;
        line-height: 14px;
        padding: 0;
        margin: 0;
      }
    }
  }

  .web-load-wrap {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .web-chat__main-content {
    flex: 1;
    background-color: #fff;
    position: relative;
    display: flex;
    display: -webkit-flex;
    overflow: hidden;

    .section__date {
      width: 100%;
      padding: 20px 0;
      text-align: center;
      font-family: 'Muli', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15.104px;
      letter-spacing: -0.03em;
      color: #b7b7cb;
    }

    .m-flex-m {
      display: flex;
      flex-direction: column-reverse;
    }

    .messages {
      overflow: hidden;
      flex: 1 1 auto;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      padding: 0;
      overflow: auto;
      height: 100vh;

      .messages-history {
        overflow-y: auto;
        flex: 1 1 auto;
        display: block;
        padding: 70px 20px 20px;

        &.banner-true {
          padding: 130px 20px 20px;
        }

        .image__mw {
          border-radius: 9.44px;
          max-width: 440px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          min-width: 0;
          word-wrap: break-word;

          img {
            width: 100%;
            border-radius: 9.44px;
          }
        }

        .img_w_txt {
          img {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }
        }

        .bottom {
          margin-top: 0;
        }

        .super-bottom {
          margin-top: 0;
        }

        .text__main-wrap {
          display: flex;
          display: -webkit-flex;
          flex-direction: row;
          -webkit-flex-direction: row;
          padding: 10px 0;
          width: fit-content;

          .avatar-w {
            width: fit-content;

            .ava__non-image {
              width: 34px;
              height: 34px;
              border-radius: 50%;
              font-family: 'Muli', sans-serif;
              font-style: normal;
              font-weight: bold;
              font-size: 15.104px;
              display: flex;
              display: -webkit-flex;
              align-items: center;
              justify-content: center;
              letter-spacing: -0.03em;
              color: #ffffff;
              background: #5ba0f1;
            }
          }

          .info-w {
            .message-c {
              padding: 13px 17px;
              border-radius: 28.32px;
              border-top-left-radius: 0.944px;
              font-family: 'Muli', sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 15.104px;
              line-height: 21px;
              letter-spacing: -0.02em;
              color: #47475f;
              background: #f4f4fa;
              margin-top: 4px;
            }

            .message-m {
              background: #ffffff;
              box-shadow: 0px 18.88px 66.08px rgba(191, 191, 221, 0.3);
            }

            .m_w_img {
              margin-top: 0px;
              border-top-left-radius: 0px;
              border-top-right-radius: 0px;
              word-wrap: break-word;
              flex: 1 1 auto;
            }
          }
        }

        .add-top {
          padding-top: 20px;
        }
      }

      .tr__footer {
        width: 100%;
        background: rgba(255, 255, 255, 0.75);
        backdrop-filter: blur(28.32px);
        padding: 19px 24px 24px 24px;

        .section__inp {
          display: flex;
          display: -webkit-flex;
          flex-direction: row;
          margin: 0;
          padding: 0;
          align-items: center;
          position: relative;

          .send-btn {
            position: absolute;
            right: 6px;
            border-radius: 50%;
            border: none;
            height: 40px;
            width: 40px;
            outline: none !important;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #1890ff;

            span {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              transform: rotate(-45deg);
              color: #fff !important;
              font-size: 20px;
              margin-bottom: 5px;
              margin-left: 4px;
            }
          }

          textarea.chat-inp {
            background: #ffffff;
            box-shadow: 0px 20px 70px rgba(191, 191, 221, 0.4);
            border-radius: 25px;
            flex: 1;
            padding: 14.9px 16.5px;
            padding-right: 48px;
            color: #47475f;
            font-family: 'Muli', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15.104px;
            line-height: 18px;
            letter-spacing: -0.02em;
            outline: none;
            overflow: hidden;
            min-height: 50px;
            max-height: 100px;
            resize: none;
            border-color: #ecf0f1;
          }

          ::placeholder {
            font-family: 'Muli', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15.104px;
            line-height: 18px;
            letter-spacing: -0.02em;
            color: #b7b7cb;
          }
        }
      }
    }

    .creating {
      overflow: hidden;
      flex: 1 1 auto;
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      padding: 0;
      margin-top: 70px;

      @media only screen and (max-width: 1170px) {
        flex-direction: column;
      }

      @media only screen and (max-width: 860px) {
        margin-top: 90px;
      }

      .create-b {
        flex: 11;
      }

      .create-s {
        flex: 7;
      }

      .b-n {
        border: none;
      }

      .b-r {
        border-right: thin solid gray;
      }

      .f {
        flex: 1;
      }

      .m-c {
        padding: 10px;
      }

      .f-14 {
        flex: 14;
      }

      .f-15 {
        flex: 15;
      }

      .property-section-description {
        margin-left: 25%;
        color: rgba(0, 0, 0, 0.85);
        margin-top: -32px;
      }
      .property-section-description-additional {
        margin-left: 25%;
        color: rgba(0, 0, 0, 0.85);
      }
      .property-section-actions {
        margin-left: 25%;
        margin-bottom: 24px;
      }
    }

    .creating-v {
      flex-direction: column;
    }

    .text__m-wrap {
      padding: 10px 0;
    }

    .text__m-wrap-magic {
      padding: 10px 0;
    }

    .text__m-diff {
      padding-bottom: 20px;
    }

    .tr__header {
      width: 100%;
      min-height: 60px;
      max-height: 120px;
      position: absolute;
      top: 0px;
      right: 0px;
      left: 0px;
      background: rgba(255, 255, 255, 0.75);
      backdrop-filter: blur(28.32px);
      padding: 12px 16px;
      z-index: 9999;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      display: flex;
      flex-direction: row;
      align-items: center;

      .banner-text {
        overflow-y: hidden;
      }
    }

    .tr__b {
      position: absolute;
      top: 18px;
      right: 18px;
    }
  }

  .wc__solo {
    overflow: unset;
    /* height: 100%; */
  }

  .spacerh5 {
    width: 5px;
  }

  .d-zone {
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px dashed gray;
    margin: 20px 0;

    span {
      border: none;
    }
  }

  .cm-side {
    @media only screen and (max-width: 480px) {
      display: block;
    }
  }
`;

export const LoginWrapper = styled.div`
  height: 100vh;
  display: flex;
  margin-top: 80px;
  justify-content: center;

  .l-box {
    background: #fff;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    height: 240px;
    padding: 10px 30px 0;

    .google-icon {
      cursor: pointer;
    }
  }
`;
