import { Form, Input, Button, message } from 'antd';
import React from 'react';
import axios from 'axios';
import store from 'store2';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { get } from 'lodash';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export function LoginForm() {
  const history = useHistory();
  const onFinish = async (values) => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_ADMIN_LOGIN_URL,
        values
      );

      console.log({ data });

      if (data) {
        store.set('constitution-token', data);
        const decoded = jwtDecode(data);
        const email = get(decoded, 'email', '');
        store.set('constitution_email', email);

        return history.push('/');
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      style={{ width: 420 }}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Required',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Required',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
