import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Alert, Button, Descriptions, message, Table } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import NumberFormat from 'react-number-format';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { PAYOUT } from './apollo_queries';
import { LAST_TRANSACTION } from './apollo_subscriptions';

export function PayoutDetail({
  entityAccounts = [],
  constLoading = false,
  hasIndividualUserAccount = false,
  hasEntityUserAccount = false,
}) {
  const history = useHistory();
  const match = useRouteMatch();
  const payoutId = get(match, 'params.id', '');
  const { data, loading, error, refetch } = useQuery(PAYOUT, {
    variables: { payoutId },
  });
  const [businessUserAccountId, setBusinessUserAccountId] = useState('');
  const lastTransactionData = useSubscription(LAST_TRANSACTION, {
    variables: { id: businessUserAccountId },
  });

  const idsub = get(lastTransactionData, 'data.lastTransactionStatus.id', '');
  const msg = get(
    lastTransactionData,
    'data.lastTransactionStatus.message',
    ''
  );

  useEffect(() => {
    if (!isEmpty(entityAccounts)) {
      const d = entityAccounts.filter((i) => i.isDefault)[0];
      if (!isEmpty(d)) {
        setBusinessUserAccountId(d._id);
      }
    }
  }, [entityAccounts]);

  useEffect(() => {
    async function lt() {
      if (idsub) {
        message.info(`Last transaction update: ${msg}`, 6);
        await refetch();
      }
    }

    lt();
  }, [idsub, refetch, msg]);

  if (loading || constLoading) {
    return (
      <div className="web-load-wrap">
        <LoadingOutlined />
      </div>
    );
  }

  if (!hasIndividualUserAccount || !hasEntityUserAccount) {
    return (
      <div className="web-chat__main-content">
        <Link to="/register-to-sila">Register business account here</Link>
      </div>
    );
  }

  const { amount, email } = get(data, 'payout', {});
  const {
    address,
    investmentAmount,
    investmentBalance,
    fundingAmount,
    status,
  } = get(data, 'payout.investment', {});
  const transactions = get(data, 'payout.transactions', []);

  const columns = [
    {
      title: 'To',
      render: (_, { toAccount, toUserBankAccount }) => (
        <span>
          {!isEmpty(toAccount)
            ? toAccount.type === 'entity'
              ? toAccount.accountName
              : `${toAccount.firstName} ${toAccount.lastName} (Individual)`
            : `${toUserBankAccount.name} (Bank Account)`}
        </span>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'sila_amount',
      render: (amt) => (
        <NumberFormat
          displayType="text"
          value={parseFloat(amt) / 100}
          thousandSeparator={true}
          prefix={'$'}
          renderText={(value) => <div>{value}</div>}
        />
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created_epoch',
      render: (c) => moment(parseInt(c) * 1000).format('LLL'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
  ];

  return (
    <div style={{ padding: 10 }}>
      {error && <Alert message={error.message} />}
      <Descriptions
        title="Payout"
        size="small"
        extra={
          <Button
            onClick={() => history.push('/properties-and-investments')}
            htmlType="button"
            type="primary"
            size="small"
          >
            New payout
          </Button>
        }
        bordered
      >
        <Descriptions.Item label="Total amount">
          <NumberFormat
            displayType="text"
            value={amount}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => <div>{value}</div>}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Initiator">{email}</Descriptions.Item>
      </Descriptions>
      <div style={{ height: 20 }}></div>
      <Descriptions
        title="Property"
        bordered
        extra={
          <Button
            onClick={async () => refetch()}
            htmlType="button"
            type="dashed"
            size="small"
          >
            Refresh
          </Button>
        }
      >
        <Descriptions.Item label="Address">{address}</Descriptions.Item>
        <Descriptions.Item label="Status">
          {status === 'available_investment'
            ? 'Available Investment'
            : 'Previously Funded'}
        </Descriptions.Item>
        <Descriptions.Item label="Investment Amount">
          <NumberFormat
            displayType="text"
            value={investmentAmount}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => <div>{value}</div>}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Funding Amount">
          <NumberFormat
            displayType="text"
            value={fundingAmount}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => <div>{value}</div>}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Balance">
          <NumberFormat
            displayType="text"
            value={investmentBalance}
            thousandSeparator={true}
            prefix={'$'}
            renderText={(value) => <div>{value}</div>}
          />
        </Descriptions.Item>
      </Descriptions>

      <div style={{ height: 30 }}></div>

      <Table
        title={() => <h3>Transactions</h3>}
        size="small"
        bordered
        dataSource={transactions}
        columns={columns}
        pagination={{
          total: transactions.length,
          position: ['bottomCenter'],
          hideOnSinglePage: true,
          pageSize: transactions && transactions.length,
        }}
        rowKey="transaction_id"
      />
    </div>
  );
}
