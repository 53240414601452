import { Alert, Button, Table, Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-apollo';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router';
import { useState } from 'react/cjs/react.development';
import { PAYMENTS } from './apollo_queries';
import { get } from 'lodash';

const columns = [
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (status) =>
      status === 'success' ? (
        <Tag color="green">{status.toUpperCase()}</Tag>
      ) : (
        <Tag color="red">{status.toUpperCase()}</Tag>
      ),
  },
  {
    title: 'Transaction Type',
    key: 'type',
    dataIndex: 'type',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (amount) => (
      <NumberFormat
        displayType="text"
        value={amount}
        thousandSeparator={true}
        prefix={'$'}
        renderText={(value) => <span>{value}</span>}
      />
    ),
  },
  {
    title: 'Account Name',
    key: 'userAccount',
    dataIndex: 'userAccount',
    render: (u) => <span>{u?.accountName || '-'}</span>,
  },
  {
    title: 'Transaction Date',
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (createdAt) => <span>{moment(createdAt).format('LLL')}</span>,
  },
];

export function Payments() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);

  const { data, error, loading, refetch } = useQuery(PAYMENTS, {
    variables: {
      transactionItemInput: {
        count,
        page,
        type: ['PAYMENT'],
      },
    },
  });

  const onChange = (pagination, _) => {
    if (pagination.current !== page) {
      setPage(pagination.current);
      history.push(`?p=${pagination.current}`);
    }

    if (count !== pagination.pageSize) {
      setCount(pagination.pageSize);
    }
  };

  const items = get(data, 'adminPayments', []);
  return (
    <div style={{ paddingBottom: 30 }} className="web-chat__main-content">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            Payments
          </div>
        </div>
        <div className="tr__b">
          <Button onClick={() => refetch()}>Refresh</Button>

          <Button
            style={{ marginLeft: 15 }}
            role="button"
            onClick={() => history.goBack()}
            type="primary"
            danger
          >
            Back
          </Button>
        </div>
      </div>
      <div className="creating">
        <div className="create-b b-n m-c">
          <div style={{ background: 'white' }}>
            {error && <Alert message={error.message} type="error" />}
            <Table
              loading={loading}
              columns={columns}
              dataSource={items}
              onChange={onChange}
              rowKey={'_id'}
              size="small"
              pagination={{
                pageSize: items.length,
                pageSizeOptions: ['10', '20', '30', '40', '50'],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
