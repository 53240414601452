import React from 'react';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import ClientApp from './ClientApp';
import Login from './Login';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect from="/login/failure" to="/login" />
        <Route path="/login" component={Login} exact />
        <Route path="/" component={ClientApp} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
