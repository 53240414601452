import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Input,
  Form,
  message,
  DatePicker,
  Upload,
  InputNumber,
  Select,
} from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';

import { CREATE_INVESTMENT } from './apollo_mutations';
import { omit, get, isEmpty } from 'lodash';
import {
  currencyFormatter,
  currencyFormatterNoDecimal,
  currencyParser,
} from './PropertyDetails';
import { TAGS } from './apollo_queries';
import { tagTypes } from '../guide';

const FormItem = Form.Item;
const { Option } = Select;

const H3 = styled.h3`
  color: #95a5a6;
`;

export function AddProperty({
  constLoading,
  hasEntityUserAccount,
  hasIndividualUserAccount,
}) {
  const history = useHistory();
  const [form] = Form.useForm();
  const [display, setDisplay] = useState('');

  useEffect(() => {
    setDisplay('');
  }, []);

  const { data, loading: tagLoading } = useQuery(TAGS);
  const [mutate, { loading, error }] = useMutation(CREATE_INVESTMENT);

  if (constLoading || tagLoading) {
    return (
      <div className="web-load-wrap">
        <LoadingOutlined />
      </div>
    );
  }

  if (!hasIndividualUserAccount || !hasEntityUserAccount) {
    return (
      <div className="web-chat__main-content">
        <Link to="/register-to-sila">Register business account here</Link>
      </div>
    );
  }

  const allTags = data?.tags || [];

  const loanTags = allTags.filter((i) => i.type === tagTypes.loan.name);
  const propTags = allTags.filter((i) => i.type === tagTypes.property.name);

  return (
    <div className="web-chat__main-content wc__solo">
      <div className="tr__header">
        <div className="top_header">
          <div style={{ fontWeight: 'bolder' }} className="sub-text">
            Add a property
          </div>
        </div>
        <div className="tr__b">
          <Button
            style={{ marginLeft: 10 }}
            role="button"
            onClick={() => history.push('/')}
            type="primary"
            danger
          >
            Back
          </Button>
        </div>
      </div>
      <div className="creating">
        <div className="create-b b-n b-r m-c">
          {error && (
            <div>{`Error, please refresh the page: ${error.message}`}</div>
          )}
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 20 }}
            onFinish={async (values) => {
              const {
                image,
                maturityDate,
                documents,
                seriesNoteListing,
                loanTag,
                propertyTag,
              } = values;

              const tags = [];

              if (loanTag) {
                tags.push(loanTag);
              }

              if (propertyTag) {
                tags.push(propertyTag);
              }

              const data = omit(values, [
                'image',
                'documents',
                'maturityDate',
                'seriesNoteListing',
                'loanTag',
                'propertyTag',
              ]);

              const payload = { data };
              payload.data['tags'] = tags;

              if (!isEmpty(image)) {
                payload['image'] = image[0]['originFileObj'];
              }

              if (!isEmpty(seriesNoteListing)) {
                payload['seriesNoteListing'] =
                  seriesNoteListing[0]['originFileObj'];
              }

              if (maturityDate) {
                payload['data']['maturityDate'] = moment(maturityDate).format();
              }

              if (!isEmpty(documents)) {
                payload['documents'] = documents.map(
                  (item) => item['originFileObj']
                );
              }

              if (payload?.data?.asIsValuePurchasePrice !== undefined) {
                payload.data[
                  'asIsValuePurchasePrice'
                ] = payload.data.asIsValuePurchasePrice.toString();
              }

              if (payload?.data?.rehabBudget !== undefined) {
                payload.data[
                  'rehabBudget'
                ] = payload.data.rehabBudget.toString();
              }

              if (payload?.data?.arv !== undefined) {
                payload.data['arv'] = payload.data.arv.toString();
              }

              payload['data']['investmentAmount'] = parseFloat(
                values.investmentAmount || 0
              );

              payload['data']['fundingAmount'] = parseFloat(
                values.fundingAmount || 0
              );

              try {
                const { data } = await mutate({ variables: payload });
                const investmentId = get(data, 'createInvestment._id', null);

                if (investmentId) {
                  setDisplay(investmentId);
                }
              } catch (error) {
                message.info('Error', error.message);
              }
            }}
            name="create-investment"
          >
            <div style={{ background: 'white' }}>
              <H3>Information</H3>
              <FormItem name="address" label="Address" required>
                <Input name="name" placeholder="Property address" required />
              </FormItem>
              <FormItem name="city" label="City" required>
                <Input name="city" placeholder="City" required />
              </FormItem>
              <FormItem name="state" label="State" required>
                <Input name="state" placeholder="State" required />
              </FormItem>
              <FormItem name="zipCode" label="ZIP code" required>
                <Input name="zipCode" placeholder="ZIP code" required />
              </FormItem>

              <FormItem name="propertyTag" label="Property tag">
                <Select required>
                  {propTags.map((item) => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem name="loanTag" label="Loan tag">
                <Select required>
                  {loanTags.map((item) => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem
                name="image"
                label="Property Image"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }

                  return e && e.fileList;
                }}
              >
                <Upload
                  name="image"
                  listType="picture"
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>
                    Click to upload display image
                  </Button>
                </Upload>
              </FormItem>

              <FormItem
                name="seriesNoteListing"
                label="Series Note Listing"
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }

                  return e && e.fileList;
                }}
              >
                <Upload
                  name="seriesNoteListing"
                  listType="text"
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>
                    Click to upload series note listing
                  </Button>
                </Upload>
              </FormItem>

              <H3>Payment Terms</H3>
              <FormItem name="originalLoanTerm" label="Original Loan Term">
                <Input
                  name="originalLoanTerm"
                  placeholder="Original Loan Term"
                  type="number"
                  addonAfter="months"
                />
              </FormItem>
              <FormItem
                name="originalLoanTermInfo"
                label="Original Loan Term info"
              >
                <Input
                  name="originalLoanTermInfo"
                  placeholder="Original Loan Term info"
                />
              </FormItem>
              <FormItem name="maturityDate" label="Maturity Date">
                <DatePicker placeholder="Maturity Date" name="maturityDate" />
              </FormItem>
              <FormItem name="extensionOption" label="Extension Option">
                <Input name="extensionOption" placeholder="Extension Option" />
              </FormItem>
              <FormItem
                name="extensionOptionInfo"
                label="Extension Option info"
              >
                <Input
                  name="extensionOptionInfo"
                  placeholder="Extension Option info"
                />
              </FormItem>
              <FormItem name="extensionFee" label="Extension Fee">
                <Input
                  name="extensionFee"
                  placeholder="Extension Fee"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem name="extensionFeeInfo" label="Extension Fee info">
                <Input
                  name="extensionFeeInfo"
                  placeholder="Extension Fee info"
                />
              </FormItem>
              <FormItem name="prepaymentPenalty" label="Prepayment Penalty">
                <Input
                  name="prepaymentPenalty"
                  placeholder="Prepayment Penalty"
                />
              </FormItem>
              <FormItem
                name="prepaymentPenaltyInfo"
                label="Prepayment Penalty info"
              >
                <Input
                  name="prepaymentPenaltyInfo"
                  placeholder="Prepayment Penalty info"
                />
              </FormItem>

              <H3>Yield Details</H3>
              <FormItem
                name="investorYieldMaturity"
                label="Investor Yield to Maturity"
              >
                <Input
                  name="investorYieldMaturity"
                  placeholder="Investor Yield to Maturity"
                  type="number"
                  addonAfter="%"
                />
              </FormItem>
              <FormItem
                name="investorYieldMaturityInfo"
                label="Investor Yield to Maturity info"
              >
                <Input
                  name="investorYieldMaturityInfo"
                  placeholder="Investor Yield to Maturity info"
                />
              </FormItem>

              <FormItem
                name="borrowerPaymentInsurance"
                label="Borrower Payment Insurance"
              >
                <Input
                  name="borrowerPaymentInsurance"
                  placeholder="Borrower Payment Insurance"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem
                name="borrowerPaymentInsuranceInfo"
                label="Borrower Payment Insurance Info"
              >
                <Input
                  name="borrowerPaymentInsuranceInfo"
                  placeholder="Borrower Payment Insurance Info"
                />
              </FormItem>

              <FormItem name="servicingFee" label="Servicing Fee">
                <Input
                  name="servicingFee"
                  placeholder="Servicing Fee"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem name="netYield" label="Net Yield">
                <Input
                  name="netYield"
                  placeholder="Net Yield"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>

              <H3>Note Information</H3>
              <FormItem
                name="investmentAmount"
                label="Investment Amount"
                required
              >
                <InputNumber
                  formatter={currencyFormatterNoDecimal}
                  parser={currencyParser}
                  style={{ width: '100%' }}
                  required
                />
              </FormItem>
              <FormItem
                name="investmentAmountInfo"
                label="Investment Amount info"
              >
                <Input
                  name="investmentAmountInfo"
                  placeholder="Investment Amount info"
                />
              </FormItem>

              <FormItem name="fundingAmount" label="Funding Amount" required>
                <InputNumber
                  formatter={currencyFormatterNoDecimal}
                  parser={currencyParser}
                  style={{ width: '100%' }}
                  required
                />
              </FormItem>

              <FormItem name="percentFunded" label="Percent Funded">
                <Input
                  name="percentFunded"
                  placeholder="Percent Funded"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>

              <FormItem name="notePosition" label="Note Position">
                <Input name="notePosition" placeholder="Note Position" />
              </FormItem>
              <FormItem name="notePositionInfo" label="Note Position info">
                <Input
                  name="notePositionInfo"
                  placeholder="Note Position info"
                />
              </FormItem>
              <FormItem name="asIsLoanToValue" label="As-Is Loan to Value">
                <Input
                  name="asIsLoanToValue"
                  placeholder="As-Is Loan to Value"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem name="arvLoanToValue" label="ARV Loan to Value">
                <Input
                  name="arvLoanToValue"
                  placeholder="ARV Loan to Value"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem name="repaymentType" label="Repayment Type">
                <Input name="repaymentType" placeholder="Repayment Type" />
              </FormItem>
              <FormItem name="repaymentTypeInfo" label="Repayment Type Info">
                <Input
                  name="repaymentTypeInfo"
                  placeholder="Repayment Type Info"
                />
              </FormItem>
              <FormItem name="termLength" label="Term Length">
                <Input
                  name="termLength"
                  placeholder="Term Length"
                  addonAfter="months"
                  type="number"
                />
              </FormItem>
              <FormItem name="termLengthInfo" label="Term Length info">
                <Input name="termLengthInfo" placeholder="Term Length info" />
              </FormItem>
              <FormItem name="loanPurpose" label="Loan Purpose">
                <Input name="loanPurpose" placeholder="Loan Purpose" />
              </FormItem>
              <FormItem name="loanPurposeInfo" label="Loan Purpose info">
                <Input name="loanPurposeInfo" placeholder="Loan Purpose info" />
              </FormItem>
              <FormItem name="exitFee" label="Exit Fee">
                <Input
                  name="exitFee"
                  placeholder="Exit Fee"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem name="exitFeeInfo" label="Exit Fee info">
                <Input name="exitFeeInfo" placeholder="Exit Fee info" />
              </FormItem>
              <FormItem
                name="borrowerCreditScore"
                label="Borrower Credit Score"
              >
                <Input
                  name="borrowerCreditScore"
                  placeholder="Borrower Credit Score"
                />
              </FormItem>

              <H3>Property Details</H3>
              <FormItem name="noteListing" label="Note listing #" required>
                <Input
                  name="noteListing"
                  placeholder="Note listing #"
                  required
                />
              </FormItem>

              <FormItem name="performance" label="Performance">
                <Input name="performance" placeholder="Performance" />
              </FormItem>

              <FormItem name="numberOfUnits" label="Number of Units">
                <Input name="numberOfUnits" placeholder="Number of Units" />
              </FormItem>
              <FormItem
                name="asIsValuePurchasePrice"
                label="As-Is Value or Purchase Price"
              >
                <InputNumber
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  style={{ width: '100%' }}
                />
              </FormItem>
              <FormItem name="asIsLtv" label="As-Is LTV">
                <Input
                  name="asIsLtv"
                  placeholder="As-Is LTV"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>
              <FormItem name="rehabBudget" label="Rehab Budget">
                <InputNumber
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  style={{ width: '100%' }}
                />
              </FormItem>
              <FormItem name="rehabBudgetInfo" label="Rehab Budget info">
                <Input name="rehabBudgetInfo" placeholder="Rehab Budget info" />
              </FormItem>
              <FormItem name="arv" label="ARV">
                <InputNumber
                  formatter={currencyFormatter}
                  parser={currencyParser}
                  style={{ width: '100%' }}
                />
              </FormItem>
              <FormItem name="arvLtv" label="ARV LTV">
                <Input
                  name="arvLtv"
                  placeholder="ARV LTV"
                  addonAfter="%"
                  type="number"
                />
              </FormItem>

              <H3>Risk Rating</H3>
              <FormItem name="riskRating" label="Rating">
                <Input name="riskRating" placeholder="Rating" />
              </FormItem>
              <FormItem name="riskRatingInfo" label="Description">
                <Input name="riskRatingInfo" placeholder="Description" />
              </FormItem>

              <H3>Deal Overview</H3>
              <FormItem name="dealOverview" label="Description">
                <Input name="dealOverview" placeholder="Description" />
              </FormItem>

              <FormItem
                wrapperCol={{
                  sm: {
                    span: 16,
                    offset: 6,
                  },
                }}
              >
                <Button
                  type="default"
                  htmlType="button"
                  style={{ marginRight: 8 }}
                  onClick={() => {
                    form.resetFields();
                    setDisplay('');
                  }}
                  disabled={loading}
                >
                  Reset
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 8 }}
                  disabled={loading}
                >
                  {loading ? <LoadingOutlined /> : 'Submit'}
                </Button>
                {display && (
                  <div>
                    <a
                      href={`/properties-and-investments/${display}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Success. Click here to edit and add documents.
                    </a>
                  </div>
                )}
              </FormItem>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
