import gql from 'graphql-tag';

export const UPLOAD_FILE = gql`
  mutation uploadFile(
    $file: Upload
    $owner: String!
    $sessionId: String!
    $text: String
  ) {
    uploadFile(file: $file, owner: $owner, sessionId: $sessionId, text: $text) {
      _id
      sessionId
      owner
      text
      media {
        downloadLink
        previewLink
        type
      }
      error
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_UNREAD_TO_ZERO = gql`
  mutation updateUnreadToZero($sessionId: String!) {
    updateUnreadToZero(sessionId: $sessionId) {
      _id
      initialMessageReplied
      phoneNumber
      propertyId
      email
      name
      state
      city
      address
      showingAt
      confirmed
      leadInterested
      sectionEight
      createdAt
      updatedAt
      personCategory
      comment
      job
      unreadMessages
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation createMessage($data: CreateMessageInput!) {
    createMessage(data: $data) {
      _id
      sessionId
      owner
      text
      media {
        downloadLink
        previewLink
        type
      }
      error
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_SESSION = gql`
  mutation createSession($data: CreateSessionInput!) {
    createSession(data: $data) {
      _id
      initialMessageReplied
      phoneNumber
      propertyId
      email
      name
      state
      city
      address
      createdAt
      updatedAt
      showingAt
      confirmed
      leadInterested
      sectionEight
      personCategory
    }
  }
`;

export const UPDATE_SESSION = gql`
  mutation updateSession($data: UpdateSessionInput!) {
    updateSession(data: $data) {
      _id
      initialMessageReplied
      phoneNumber
      propertyId
      email
      name
      state
      city
      address
      createdAt
      updatedAt
      showingAt
      confirmed
      leadInterested
      sectionEight
      personCategory
    }
  }
`;

export const CREATE_SESSION_BULK = gql`
  mutation createSessionBulk($data: CreateSessionBulkInput!) {
    createSessionBulk(data: $data) {
      _id
      initialMessageReplied
      phoneNumber
      propertyId
      email
      name
      state
      city
      address
      createdAt
      updatedAt
      showingAt
      confirmed
      leadInterested
      sectionEight
      personCategory
    }
  }
`;

export const CREATE_EMPLOYEE_SESSION_BULK = gql`
  mutation createEmployeeSessionBulk(
    $data: CreateEmployeeSessionBulkInputData!
  ) {
    createEmployeeSessionBulk(data: $data) {
      _id
      initialMessageReplied
      phoneNumber
      propertyId
      email
      name
      state
      city
      address
      createdAt
      updatedAt
      showingAt
      confirmed
      leadInterested
      sectionEight
      personCategory
    }
  }
`;

export const CREATE_INVESTMENT = gql`
  mutation createInvestment(
    $data: CreateInvestmentInput!
    $image: Upload
    $documents: [Upload]
    $seriesNoteListing: Upload
  ) {
    createInvestment(
      data: $data
      image: $image
      documents: $documents
      seriesNoteListing: $seriesNoteListing
    ) {
      _id
      address
      investorYieldMaturity
      investorYieldMaturityInfo
      borrowerPaymentInsurance
      borrowerPaymentInsuranceInfo
      servicingFee
      netYield
      investmentAmount
      investmentAmountInfo
      notePosition
      notePositionInfo
      asIsLoanToValue
      arvLoanToValue
      repaymentType
      termLength
      termLengthInfo
      loanPurpose
      loanPurposeInfo
      exitFee
      exitFeeInfo
      borrowerCreditScore
      propertyType
      numberOfUnits
      asIsValuePurchasePrice
      asIsLtv
      rehabBudget
      rehabBudgetInfo
      arv
      arvLtv
      ltv
      riskRating
      riskRatingInfo
      dealOverview
      originalLoanTerm
      originalLoanTermInfo
      extensionOption
      extensionOptionInfo
      extensionFee
      extensionFeeInfo
      prepaymentPenalty
      prepaymentPenaltyInfo
      imageUrl
      city
      state
      zipCode
      term
      amount
      createdAt
      updatedAt
      maturityDate
      documents {
        _id
        name
        url
        investmentId
        createdAt
        updatedAt
      }
      funds {
        _id
        investmentId
        amount
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_INVESTMENT = gql`
  mutation updateInvestment(
    $investmentId: String!
    $data: CreateInvestmentInput!
    $image: Upload
    $seriesNoteListing: Upload
    $documents: [Upload]
  ) {
    updateInvestment(
      data: $data
      image: $image
      seriesNoteListing: $seriesNoteListing
      documents: $documents
      investmentId: $investmentId
    ) {
      _id
      address
      investorYieldMaturity
      investorYieldMaturityInfo
      servicingFee
      netYield
      investmentAmount
      investmentAmountInfo
      notePosition
      notePositionInfo
      asIsLoanToValue
      arvLoanToValue
      repaymentType
      termLength
      termLengthInfo
      loanPurpose
      loanPurposeInfo
      exitFee
      exitFeeInfo
      borrowerCreditScore
      propertyType
      numberOfUnits
      asIsValuePurchasePrice
      asIsLtv
      rehabBudget
      rehabBudgetInfo
      arv
      arvLtv
      ltv
      riskRating
      riskRatingInfo
      dealOverview
      originalLoanTerm
      originalLoanTermInfo
      extensionOption
      extensionOptionInfo
      extensionFee
      extensionFeeInfo
      prepaymentPenalty
      prepaymentPenaltyInfo
      imageUrl
      city
      state
      zipCode
      term
      amount
      createdAt
      updatedAt
      maturityDate
      documents {
        _id
        name
        url
        investmentId
        createdAt
        updatedAt
      }
      funds {
        _id
        investmentId
        amount
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_INVESTMENT_DOCUMENT = gql`
  mutation updateInvestmentDocument(
    $investmentDocumentId: String!
    $isAttached: Boolean
  ) {
    updateInvestmentDocument(
      investmentDocumentId: $investmentDocumentId
      isAttached: $isAttached
    ) {
      _id
      name
      url
      investmentId
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_AS_ADMIN_BUSINESS = gql`
  mutation updateAsAdminBusiness(
    $address: String!
    $city: String!
    $state: String!
    $zip: String!
    $dateOfBirth: String!
    $ssn: String!
    $phone: String!
    $ein: String!
    $entity_name: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $business_type: String!
    $business_website: String
  ) {
    updateAsAdminBusiness(
      address: $address
      city: $city
      state: $state
      zip: $zip
      dateOfBirth: $dateOfBirth
      ssn: $ssn
      phone: $phone
      ein: $ein
      entity_name: $entity_name
      firstName: $firstName
      lastName: $lastName
      email: $email
      business_type: $business_type
      business_website: $business_website
    ) {
      _id
      email
      firstName
      lastName
      createdAt
      updatedAt
      phoneNumber
      dob
      kyc
      ssn
      city
      state
      zip
      address
      isAdmin
      isCertified
      kyc
      availableFund
      businessHandle
      isAdmin
      isCertified
      businessName
      userAccountsAdmin {
        _id
        accountName
      }
    }
  }
`;

export const REQUEST_KYC_ADMIN = gql`
  mutation {
    requestUserKycAdmin {
      _id
      kyc
      isCertified
      businessHandle
    }
  }
`;

export const CHECK_KYC_ADMIN = gql`
  mutation {
    checkUserKycAdmin {
      _id
      kyc
      isCertified
    }
  }
`;

export const LINK_ACCOUNT = gql`
  mutation linkAccount(
    $userAccountId: String!
    $accountName: String!
    $token: String!
    $bankAccountId: String!
  ) {
    linkAccount(
      userAccountId: $userAccountId
      accountName: $accountName
      token: $token
      bankAccountId: $bankAccountId
    ) {
      match_score
      message
      account_name
    }
  }
`;

export const REDEEM = gql`
  mutation redeemAdmin(
    $userAccountId: String!
    $investmentId: String!
    $amount: Float!
    $accountName: String!
  ) {
    redeemAdmin(
      userAccountId: $userAccountId
      investmentId: $investmentId
      amount: $amount
      accountName: $accountName
    ) {
      reference
      status
      message
      descriptor
      transaction_id
    }
  }
`;

export const REDEEM_LOCAL = gql`
  mutation redeem(
    $userAccountId: String!
    $amount: Float!
    $accountName: String!
  ) {
    redeem(
      userAccountId: $userAccountId
      amount: $amount
      accountName: $accountName
    ) {
      reference
      status
      message
      descriptor
      transaction_id
    }
  }
`;

export const DELETE_INVESMENT = gql`
  mutation deleteInvestmentClean($investmentId: String!) {
    deleteInvestmentClean(investmentId: $investmentId) {
      _id
    }
  }
`;

export const NOTIFY_EQUITY_ASSET_UPDATES = gql`
  mutation notifyEquityAssetUpdates($investmentId: String!) {
    notifyEquityAssetUpdates(investmentId: $investmentId) {
      _id
    }
  }
`;

export const SET_EQUITY_ASSET_UPDATED = gql`
  mutation setEquityAssetUpdated($investmentId: String!) {
    setEquityAssetUpdated(investmentId: $investmentId) {
      _id
    }
  }
`;

export const CREATE_SINGLE_EQUITY_ASSET = gql`
  mutation createSingleEquityAsset($investmentId: String!) {
    createSingleEquityAsset(investmentId: $investmentId) {
      _id
    }
  }
`;

export const CREATE_INDIVIDUAL = gql`
  mutation createIndividual(
    $ssn: String!
    $dateOfBirth: String!
    $phone: String!
    $zip: String!
    $state: String!
    $city: String!
    $address: String!
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    createIndividual(
      ssn: $ssn
      dateOfBirth: $dateOfBirth
      phone: $phone
      zip: $zip
      state: $state
      city: $city
      address: $address
      firstName: $firstName
      lastName: $lastName
      email: $email
    ) {
      _id
      kyc
    }
  }
`;

export const CREATE_ENTITY = gql`
  mutation createEntity(
    $business_website: String
    $zip: String!
    $state: String!
    $city: String!
    $phone: String!
    $address: String!
    $business_type: String!
    $ein: String!
    $accountName: String
  ) {
    createEntity(
      business_website: $business_website
      zip: $zip
      state: $state
      city: $city
      phone: $phone
      address: $address
      business_type: $business_type
      ein: $ein
      accountName: $accountName
    ) {
      _id
      kyc
    }
  }
`;

export const CHECK_KYC = gql`
  mutation checkKyc($userAccountId: String!) {
    checkKyc(userAccountId: $userAccountId) {
      _id
      kyc
    }
  }
`;

export const CERTIFY = gql`
  mutation certify($userAccountId: String!) {
    certify(userAccountId: $userAccountId) {
      _id
      isCertified
    }
  }
`;

export const SET_DEFAULT_USER_ACCOUNT = gql`
  mutation setDefaultUserAccount($userAccountId: String!) {
    setDefaultUserAccount(userAccountId: $userAccountId) {
      _id
      isDefault
    }
  }
`;

export const ASSIGN_WALLETS = gql`
  mutation assignWalletToExistingInvestments($userAccountId: String!) {
    assignWalletToExistingInvestments(userAccountId: $userAccountId)
  }
`;

export const UPDATE_SILA_FAILURE = gql`
  mutation updateSilaFailure($data: UpdateSilaFailureInput!) {
    updateSilaFailure(data: $data) {
      _id
      formattedTag
      rawTag
      instruction
      type
      requirements
    }
  }
`;

export const CREATE_SILA_FAILURE = gql`
  mutation createSilaFailure($data: CreateSilaFailureInput!) {
    createSilaFailure(data: $data) {
      _id
      formattedTag
      rawTag
      instruction
      type
      requirements
    }
  }
`;

export const UPDATE_PPM = gql`
  mutation updatePpm($ppm: Upload!) {
    updatePpm(ppm: $ppm) {
      _id
      ppm
    }
  }
`;

export const UPDATE_SUBSCRIPTION_AGREEMENT = gql`
  mutation updateSubscriptionAgreement($subscriptionAgreement: Upload!) {
    updateSubscriptionAgreement(subscriptionAgreement: $subscriptionAgreement) {
      _id
      subscriptionAgreement
    }
  }
`;

export const BULK_PAYOUT = gql`
  mutation bulkPayout(
    $data: [PayoutDataParams!]!
    $totalAmount: Float!
    $title: String!
    $investmentId: String!
  ) {
    bulkPayout(
      data: $data
      totalAmount: $totalAmount
      title: $title
      investmentId: $investmentId
    ) {
      _id
    }
  }
`;

export const BULK_ALTO_PAYOUT = gql`
  mutation bulkAltoPayout(
    $data: [AltoPayoutParamsInput!]!
    $totalAmount: Float!
    $title: String!
    $investmentId: String!
  ) {
    bulkAltoPayout(
      data: $data
      totalAmount: $totalAmount
      title: $title
      investmentId: $investmentId
    ) {
      _id
    }
  }
`;

export const ISSUE = gql`
  mutation issueAdmin(
    $userAccountId: String!
    $investmentId: String!
    $amount: Float!
    $accountName: String!
  ) {
    issueAdmin(
      userAccountId: $userAccountId
      investmentId: $investmentId
      amount: $amount
      accountName: $accountName
    ) {
      reference
      status
      message
      descriptor
      transaction_id
    }
  }
`;

export const INVEST_ADMIN = gql`
  mutation investAdmin(
    $amount: Float!
    $userAccountId: String!
    $investmentId: String!
    $isTransfer: Boolean
  ) {
    investAdmin(
      amount: $amount
      userAccountId: $userAccountId
      investmentId: $investmentId
      isTransfer: $isTransfer
    ) {
      type
      status
      message
      amountFunded
    }
  }
`;

export const SEND_DOC_TO_SILA = gql`
  mutation sendDocToSila(
    $failureId: String!
    $to: String!
    $from: String!
    $signature: String
  ) {
    sendDocToSila(
      failureId: $failureId
      to: $to
      from: $from
      signature: $signature
    ) {
      _id
      documentStatus
    }
  }
`;

export const RESOLVE_KYC_FAILURE = gql`
  mutation resolveKycFailure($failureId: String!) {
    resolveKycFailure(failureId: $failureId) {
      _id
      resolved
    }
  }
`;

export const CREATE_INVESTMENT_DOC = gql`
  mutation createInvestmentDocument(
    $document: Upload!
    $investmentId: String!
    $name: String!
  ) {
    createInvestmentDocument(
      document: $document
      investmentId: $investmentId
      name: $name
    ) {
      _id
    }
  }
`;

export const CREATE_ARTICLE = gql`
  mutation createArticle(
    $banner: Upload
    $parentId: String
    $slug: String
    $subtitle: String
    $tags: [String]
    $createdBy: String!
    $updateBy: String!
    $route: String!
    $title: String!
    $content: String!
  ) {
    createArticle(
      banner: $banner
      parentId: $parentId
      slug: $slug
      subtitle: $subtitle
      tags: $tags
      createdBy: $createdBy
      updateBy: $updateBy
      route: $route
      title: $title
      content: $content
    ) {
      _id
    }
  }
`;

export const UPDATE_ARTICLE = gql`
  mutation updateArticle(
    $banner: Upload
    $parentId: String
    $subtitle: String
    $tags: [String]
    $title: String
    $content: String
    $id: String!
    $updateBy: String!
    $archived: Boolean
  ) {
    updateArticle(
      banner: $banner
      parentId: $parentId
      subtitle: $subtitle
      tags: $tags
      title: $title
      id: $id
      updateBy: $updateBy
      content: $content
      archived: $archived
    ) {
      _id
      title
      subtitle
      slug
      parentId
      banner
      route
      tags
      updateBy
      createdBy
      createdAt
      updatedAt
      content
    }
  }
`;

export const DELETE_ARTICLE = gql`
  mutation deleteArticle($id: String!) {
    deleteArticle(id: $id)
  }
`;

export const CREATE_TAG = gql`
  mutation createTag($data: CreateTagInput!) {
    createTag(data: $data) {
      _id
      name
      type
      color
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation updateTag($tagId: String!, $data: UpdateTagInput!) {
    updateTag(tagId: $tagId, data: $data) {
      _id
      name
      type
      color
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_TAG = gql`
  mutation deleteTag($tagId: String!) {
    deleteTag(tagId: $tagId)
  }
`;

export const CHECK_USER_KYC_ADMIN = gql`
  mutation checkKycByAdmin($userId: String!, $userAccountId: String!) {
    checkKycByAdmin(userId: $userId, userAccountId: $userAccountId) {
      verification_status
      success
      entity_type
      message
      verification_history {
        verification_id
        verification_status
        kyc_level
        tags
        reasons
        parent_verification {
          parent_user_handle
          verification_status
          parent_verification_id
        }
      }
    }
  }
`;

export const ISSUE_FUND_CONSTI = gql`
  mutation issue(
    $accountName: String!
    $amount: Float!
    $userAccountId: String!
  ) {
    issue(
      amount: $amount
      accountName: $accountName
      userAccountId: $userAccountId
    ) {
      message
      transaction_id
    }
  }
`;

export const ISSUE_REWARD = gql`
  mutation issueReward(
    $reservedTransactionId: String!
    $amount: Float!
    $userAccountId: String!
    $adminUserAccountId: String!
  ) {
    issueReward(
      reservedTransactionId: $reservedTransactionId
      amount: $amount
      userAccountId: $userAccountId
      adminUserAccountId: $adminUserAccountId
    ) {
      message
      transaction_id
    }
  }
`;

export const SYNC_SILA = gql`
  mutation {
    syncMirrorWithSila
  }
`;

export const CREATE_BORROWER = gql`
  mutation createBorrower(
    $lastName: String!
    $firstName: String!
    $email: String!
  ) {
    createBorrower(lastName: $lastName, firstName: $firstName, email: $email)
  }
`;

export const RESEND_BORROWER_LINK = gql`
  mutation resendBorrowerLink($userId: String!) {
    resendBorrowerLink(userId: $userId)
  }
`;
