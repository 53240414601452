import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { message } from 'antd';

import { LoginWrapper } from './styles';
import { LoginForm } from './LoginForm';

const Login = () => {
  const location = useLocation();
  const { error } = qs.parse(location.search);

  useEffect(() => {
    function checkErrorParam() {
      if (error && error === 'login_required') {
        message.error('Login required');
      }
    }

    checkErrorParam();
  });

  return (
    <LoginWrapper>
      <div className="l-box">
        <h2>ConstLending Admin</h2>
        <LoginForm />
      </div>
    </LoginWrapper>
  );
};

export default Login;
